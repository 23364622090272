import { EntityInfoTypeEnum } from "@merit/issuance-client";
import { Icon } from "@merit/frontend-components";
import { Image, StyleSheet } from "react-native";
import { Text } from "@src/utils/constants";
import { useConfig } from "@src/hooks";
import DefaultOrgLogo from "@src/assets/images/default-org-logo.png";
import MeritLogo from "@src/assets/images/merit-logotype.png";
import OhioAceLogo from "@src/assets/images/ohio-ace-logo.png";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";

export type OrgLogoProps = {
  readonly merit?: Merit;
};

export const OrgLogo: FC<OrgLogoProps> = ({ merit }) => {
  const styles = StyleSheet.create({
    logo: {
      height: "100%",
      width: "100%",
    },
  });

  const config = useConfig();

  if (merit === undefined) {
    return <Icon name="defaultOrg" style={styles.logo} />;
  }

  /*
    TODO: we really should match against org ids, and we get a list via remote configs or similar
    at a minimum, the Merit full and short company names should be migrated to matching against Merit's org ID
  */
  const orgName = merit.transformedFields.orgName;
  if (orgName === Text.MERIT_COMPANY_NAME || orgName === Text.MERIT_COMPANY_SHORT_NAME) {
    return <Image resizeMode="contain" source={MeritLogo} style={styles.logo} />;
  }
  if (orgName === "Ohio ACE") {
    return <Image resizeMode="contain" source={OhioAceLogo} style={styles.logo} />;
  }

  const { id, type } = merit.issuer ?? {};
  const orgLogoUrl =
    type === EntityInfoTypeEnum.Organization &&
    id !== undefined &&
    config.externalResourcesConfig.orgLogosBaseUrl !== ""
      ? `${config.externalResourcesConfig.orgLogosBaseUrl}${config.externalResourcesConfig.orgLogoFilePrefix}${id}`
      : undefined;

  return orgLogoUrl === undefined ? (
    <Icon name="defaultOrg" style={styles.logo} />
  ) : (
    <Image
      defaultSource={DefaultOrgLogo}
      resizeMode="contain"
      source={{ uri: orgLogoUrl }}
      style={styles.logo}
    />
  );
};
