import { AcceptTermsOfService } from "@src/screens/AcceptTermsOfService";
import { DrawerNavigator, SideBarNavigator } from "@src/navigation";
import {
  LoadingScreen,
  LoggingIn,
  LoggingOut,
  LoginScreen,
  MeritDetailsScreen,
  OTAInfo,
  OnboardingMerits,
  OnboardingRegistration,
  PersonalQrCode,
  PolicyRequestDetails,
  SomethingWentWrong,
} from "@src/screens";
import { createStackNavigator } from "@react-navigation/stack";
import { msg } from "@lingui/macro";
import { useFeatureFlags, useLayoutType, useMeritAuth0, useOnboardingEffect } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMemo } from "react";
import { useOnboardingStore } from "@src/stores";
import type { ScreenParamList } from "../types";

export type RootStackParamList = Pick<
  ScreenParamList,
  | "DrawerNavigator"
  | "Loading"
  | "LoggingIn"
  | "LoggingOut"
  | "Login"
  | "MeritDetails"
  | "OnboardingMerits"
  | "OnboardingRegistration"
  | "OTAInfo"
  | "PersonalQRCode"
  | "PolicyRequestDetails"
  | "SideBarNavigator"
  | "SomethingWentWrong"
  | "TermsOfServiceAcceptance"
>;

const RootStack = createStackNavigator<RootStackParamList>();

const DEFAULT_SCREEN_OPTIONS = {
  headerShown: false,
};

export const RootStackNavigator = () => {
  const { isAuthenticated, isLoading: isAuthLoading } = useMeritAuth0();
  const onboardingStep = useOnboardingStore(state => state.onboardingStep);
  const layoutType = useLayoutType();
  const featureFlags = useFeatureFlags();
  const { _ } = useLingui();

  useOnboardingEffect();

  const navigationContent = useMemo(() => {
    if (isAuthLoading) {
      return (
        <RootStack.Screen
          component={LoggingIn}
          name="LoggingIn"
          options={{ title: _(msg({ context: "screen title", message: "Logging in" })) }}
        />
      );
    }

    if (!isAuthenticated) {
      return (
        <RootStack.Screen
          component={LoginScreen}
          name="Login"
          options={{ title: _(msg({ context: "screen title", message: "Log in" })) }}
        />
      );
    }

    switch (onboardingStep) {
      case "LOADING": {
        return (
          <RootStack.Screen
            component={LoadingScreen}
            name="Loading"
            options={{ title: _(msg({ context: "screen title", message: "Loading" })) }}
          />
        );
      }
      case "REGISTRATION": {
        return (
          <RootStack.Screen
            component={OnboardingRegistration}
            key="onboardingRegistration"
            name="OnboardingRegistration"
            options={{ title: _(msg({ context: "screen title", message: "Registration" })) }}
          />
        );
      }
      case "ONBOARDING_MERITS": {
        if (featureFlags.showOnboardingMerits === true) {
          return (
            <RootStack.Screen
              component={OnboardingMerits}
              key="onboardingMerits"
              name="OnboardingMerits"
              options={{
                cardStyle: {
                  flex: 1,
                },
                title: _(msg({ context: "screen title", message: "Onboarding" })),
              }}
            />
          );
        }
      }
      default: {
      }
    }

    return [
      layoutType === "desktop" ? (
        <RootStack.Screen component={SideBarNavigator} key="sidebar" name="SideBarNavigator" />
      ) : (
        <RootStack.Screen component={DrawerNavigator} key="drawer" name="DrawerNavigator" />
      ),
      <RootStack.Group key="loggedInModals" screenOptions={{ presentation: "modal" }}>
        <RootStack.Screen
          component={MeritDetailsScreen}
          name="MeritDetails"
          options={{ title: _(msg({ context: "screen title", message: "Details" })) }}
        />
        <RootStack.Screen
          component={PersonalQrCode}
          name="PersonalQRCode"
          options={{ title: _(msg({ context: "screen title", message: "QR Code" })) }}
        />
        {featureFlags.showMemberAppVerifications === true && (
          <RootStack.Screen
            component={PolicyRequestDetails}
            name="PolicyRequestDetails"
            options={{
              title: _(msg({ context: "screen title", message: "Verification Details" })),
            }}
          />
        )}
      </RootStack.Group>,
    ];
  }, [
    isAuthLoading,
    isAuthenticated,
    onboardingStep,
    layoutType,
    _,
    featureFlags.showOnboardingMerits,
    featureFlags.showMemberAppVerifications,
  ]);

  return (
    <RootStack.Navigator screenOptions={DEFAULT_SCREEN_OPTIONS}>
      {navigationContent}
      <RootStack.Screen
        component={AcceptTermsOfService}
        name="TermsOfServiceAcceptance"
        options={{ title: _(msg({ context: "screen title", message: "Accept Terms of Service" })) }}
      />
      <RootStack.Screen
        component={SomethingWentWrong}
        name="SomethingWentWrong"
        options={{ title: _(msg({ context: "screen title", message: "Something went wrong" })) }}
      />
      <RootStack.Screen
        component={LoggingOut}
        name="LoggingOut"
        options={{ title: _(msg({ context: "screen title", message: "Logging out" })) }}
      />
      <RootStack.Group key="universalModals" screenOptions={{ presentation: "modal" }}>
        <RootStack.Screen
          component={OTAInfo}
          name="OTAInfo"
          // OTA Info is an internal use screen for debugging OTA updates if necessary
          // eslint-disable-next-line lingui/no-unlocalized-strings
          options={{ title: "OTA Info" }}
        />
      </RootStack.Group>
    </RootStack.Navigator>
  );
};
